









import { defineComponent, PropType } from '@vue/composition-api'
import { OrderInfo } from '@/GeneratedTypes/Order/ListInfo/OrderInfo'
import OrderSummary from '@/components/OrderHistory/OrderSummary.vue'
import SelectorCard from '@/components/SelectorCard.vue'

export default defineComponent({
  name: 'OrderCard.vue',
  props: {
    order: { type: Object as PropType<OrderInfo>, required: true },
  },
  components: { OrderSummary, SelectorCard },

  setup(props, ctx) {
    return {
      selected: function (o: OrderInfo) {
        ctx.emit('select', o)
      },
    }
  },
})
