







import { defineComponent } from '@vue/composition-api'
import OrderHistoryByLeague from '@/views/Programs/Orders/vues/history/OrderHistoryByLeague.vue'

export default defineComponent({
  name: 'OrderHistory',
  components: { OrderHistoryByLeague },
})
